import React, {useEffect} from 'react'
import "./call-to-action.scss"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { Link, useStaticQuery, graphql } from "gatsby"
//AOS Import
import AOS from 'aos'
import "aos/dist/aos.css"

const CallToAction = ({ ctaData }) => {

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
    AOS.refresh();
  }, []);

    const data = useStaticQuery(graphql`
    query {
        wp {
            siteSettings {
              siteSettings {
                footerCallToAction {
                  footerCtaTitle
                  primaryFooterCtaLink {
                    title
                    target
                    url
                  }
                  secondaryFooterCtaLink {
                    url
                    title
                    target
                  }
                  footerCtaBackgroundImage {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
    }`)

    const cta = data.wp.siteSettings.siteSettings.footerCallToAction;

    // console.log("cta  - " + JSON.stringify(cta))

    return (
        <div className="cta-section">
            <GatsbyImage
                image={getImage(cta?.footerCtaBackgroundImage.localFile)}
                alt={cta?.footerCtaTitle}
                className="cta-img"
            />
            <div className="cta-inner">
                <div className="container">
                    <div className="row  justify-content-md-center">
                        <div className="col-md-10 text-center"
                        data-aos="fade-up"
                        data-aos-offset="100"
                        data-aos-delay="5">
                            <h3 className="cta-title">{cta?.footerCtaTitle}</h3>
                            <ul className="cta-buttons">
                                {cta.primaryFooterCtaLink ? (
                                    <li className="button__container">
                                        <Link className="primary-btn" to={cta?.primaryFooterCtaLink?.url}>{cta?.primaryFooterCtaLink?.title}</Link>
                                    </li>
                                ) : null}
                                {cta.secondaryFooterCtaLink ? (
                                    <li className="button__container">
                                        <Link className="white-btn" to={cta?.secondaryFooterCtaLink?.url}>{cta?.secondaryFooterCtaLink?.title}</Link>
                                    </li>
                                )
                                    : null}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallToAction