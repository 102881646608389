import React from "react"
import "./locationMap.scss"
function LocationMap() {
  return (
    <div className="google-map-wrapper sec-spacer">
      <div className="container">
        <h3 className="title-bg" style={{marginBottom: "100px"}}>Training Location</h3>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6329.923166379667!2d106.75176996444219!3d10.803860801213364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752750f8bbd589%3A0x5dbbc2ee992037bd!2zU8OibiBiw7NuZyB0csOtIGjhuqNpIChUcmkgSGFpIEZvb3RiYWxsKQ!5e0!3m2!1sen!2suk!4v1650336724699!5m2!1sen!2suk"
        width="100%"
        height="450"
        style={{ border: 0 }}
        allowfullscreen="true"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  )
}

export default LocationMap
