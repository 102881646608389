import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import CallToAction from "../call-to-action/call-to-action";
import { Link } from "gatsby";
import "./footer-global.scss"
import "../../fonts/fontawesome"


export default function FooterGlobal() {

    const data = useStaticQuery(graphql`
    query {
        allWpPost (limit: 2, sort: {fields: date, order: DESC}) {
            nodes {
              id
              uri
              title
              date(formatString: "DD MMM, YYYY")
              slug
            }
          }
        
      wp {
        siteSettings {
            siteSettings {
                facebookLink {
                title
                url
                }
                instagramLink {
                title
                url
                }
            }
        }
        acfOptionsFooter {
            footer {
              firstColumnHeading
              firstColumnText
              firstColumnText2
              thirdColumnHeading
              secondColumnHeading
              forthColumnHeading
              thirdColumnLinks {
                footerLink {
                  title
                  url
                }
              }
              copyrightText
              socialLinks {
                facebookLink {
                  url
                }
                instagramLink {
                  url
                }
                linkedinLink {
                  url
                }
                twitterLink {
                  url
                }
              }
            }
        }
      }
    }
  `)

    //Footer Data 
    const footerData = data?.wp?.acfOptionsFooter?.footer

    //Two Latest Posts Data 
    const postData = data?.allWpPost?.nodes
    const socials = data?.wp?.siteSettings?.siteSettings
    return (
        <>
        
            <CallToAction />

            <footer id="footer-section" className="footer-section">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <h4 className="footer-title">{footerData?.firstColumnHeading}</h4>
                                <div className="about-widget">
                                    <p>{footerData?.firstColumnText}</p>
                                    <p className="margin-remove">{footerData?.firstColumnText2}</p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <h4 className="footer-title">{footerData?.secondColumnHeading}</h4>
                                <div className="recent-post-widget">
                                    {postData?.map((post, index) => {
                                        return (
                                            <div className="post-item" key={index}>
                                                <div className="post-date p-1">
                                                    <span>{post?.date}</span>
                                                </div>
                                                <div className="post-desc">
                                                    <Link to={`/blog/${post?.slug}`}>
                                                        <h5 className="post-title">{post?.title}</h5>
                                                    </Link>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <h4 className="footer-title">{footerData?.thirdColumnHeading}</h4>
                                <ul className="sitemap-widget">
                                    {footerData?.thirdColumnLinks?.map((each, index) => {
                                        return (
                                            <li key={index}>
                                                <Link to={`${each?.footerLink?.url}`}>
                                                    <span><FontAwesomeIcon className="pr-2" icon={faAngleRight} /></span>
                                                    {each?.footerLink?.title}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="copyright">
                                    <p>&copy; {(new Date().getFullYear())} Saigon Raiders. All Rights Reserved. Powered by <a href="https://saigon-digital.com" target="_blank" rel="noreferrer">Saigon Digital</a> </p>
                                </div>
                            </div>
                            <div className="col-lg-8 col-md-6">
                                <div className="text-right ft-bottom-right">
                                    <div className="footer-bottom-share">
                                        <ul>
                                            <li>
                                                <a href={`${socials.facebookLink?.url}`} target="_blank" rel="noreferrer" title="Facebook" aria-label="Facebook">
                                                    <FontAwesomeIcon className="h2" icon={faFacebook} />
                                                </a>
                                            </li>
                                           
                                            <li>
                                                <a href={`${socials.instagramLink?.url}`} target="_blank" rel="noreferrer" title="Instagram" aria-label="Instagram">
                                                    <FontAwesomeIcon className="h2" icon={faInstagram} />
                                                </a>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}