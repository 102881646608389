import React, { useState, useEffect } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
//import fontawesome
import "../../fonts/fontawesome"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory, faFutbol, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./tabbed-content.scss"
import "../../styles/responsive.css"
import AOS from 'aos'
import "aos/dist/aos.css"

export default function TabbedContent({ charityIntroData }) {
    const [activeContent, setActiveContent] = useState(0)
    const [activeTab, setActiveTab] = useState(0)


    useEffect(() => {
        AOS.init({
            duration: 2000
        });
        AOS.refresh();
    }, []);

    return (
        <>
            <div className="rs-history-area">
                <div className="container">
                    <div className="row rs-vertical-bottom">
                        <div className="col-lg-12 col-md-12">
                            <div className="history-innner">
                                <ul className="nav nav-tabs tabs-menu" role="tablist" aria-owns="history-tab mission-tab vision-tab">
                                    <li className="nav-item">
                                        <a className={`nav-link ${activeTab === 0 ? "active" : ""}`} data-toggle="tab" role="tab" aria-controls="history" aria-selected="false" key={0} onClick={() => {
                                            setActiveContent(0)
                                            setActiveTab(0)
                                        }} >
                                            {/* <FontAwesomeIcon className="text-warning pb-1" icon={faHistory} /><br /> */}
                                            <span>{charityIntroData.firstTabHeading}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${activeTab === 1 ? "active" : ""}`} data-toggle="tab"  role="tab" aria-controls="history" aria-selected="false" key={0} onClick={() => {
                                            setActiveContent(1)
                                            setActiveTab(1)
                                        }} >
                                            {/* <FontAwesomeIcon className="text-warning pb-1" icon={faHistory} /><br /> */}
                                            <span>{charityIntroData.secondTabHeading}</span>
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link ${activeTab === 2 ? "active" : ""}`} id="mission-tab" data-toggle="tab"  role="tab" aria-controls="vision" aria-selected="false" key={1} onClick={() => {
                                            setActiveContent(2)
                                            setActiveTab(2)
                                        }}>
                                            {/* <FontAwesomeIcon className="text-warning pb-1" icon={faFutbol} /><br /> */}
                                            <span>{charityIntroData.thirdTabHeading}</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className="tab-content"
                                    data-aos="fade-up"
                                    data-aos-offset="100"
                                    data-aos-delay="5">
                                    <div key={0} className={`tab-pane fade show ${activeContent === 0 ? "active" : ""}`}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <GatsbyImage image={getImage(charityIntroData.image1?.localFile.childImageSharp.gatsbyImageData)} alt={"sign image"} />
                                            </div>
                                            <div className="col-md-8">
                                                <p>{charityIntroData.firstTabDescription}</p>
                                                <div className="president-info">
                                                    <div className="position">
                                                        <h4>{charityIntroData?.quoteName1}<span>{charityIntroData?.quoteTitle1}</span></h4>
                                                    </div>
                                                    <div className="signature">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div key={1} className={`tab-pane fade show ${activeContent === 1 ? "active" : ""}`}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <GatsbyImage image={getImage(charityIntroData.image2?.localFile.childImageSharp.gatsbyImageData)} alt={"sign image"} />
                                            </div>
                                            <div className="col-md-8">
                                                <p>{charityIntroData.secondTabDescription}</p>
                                                <div className="president-info">
                                                    <div className="position">
                                                        <h4>{charityIntroData?.quoteName2} <span>{charityIntroData?.quoteTitle2}</span></h4>
                                                    </div>
                                                    <div className="signature">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div key={2} className={`tab-pane fade show ${activeContent === 2 ? "active" : ""}`}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <GatsbyImage image={getImage(charityIntroData.image3?.localFile.childImageSharp.gatsbyImageData)} alt={"sign image"} />
                                            </div>
                                            <div className="col-md-8">
                                                <p>{charityIntroData.thirdTabDescription}</p>
                                                <div className="president-info">
                                                    <div className="position">
                                                        <h4>{charityIntroData?.quoteName3} <span>{charityIntroData?.quoteTitle3}</span></h4>
                                                    </div>
                                                    <div className="signature">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}