import React from "react"
import { graphql } from "gatsby"
import Navigation from "../components/navigation/navigation"
import FooterGlobal from "../components/footer-global/footer-global"
import Location from "../components/location/location"

import ContactForm from "../components/ContactForm/ContactForm"
import TabbedContent from "../components/tabbed-content/tabbled-content"
import LocationMap from "../components/LocationMap/LocationMap"

export default function ContactPage({ data }) {
  const contactData = data.wpPage

  //Location Data
  const locationData = contactData.contactpage?.location
  // Tabbed Content Data
  const tabbedContent = contactData?.contactpage?.contactClubTabbed
  //Navigation Bar
  const navData = data.wp?.siteSettings.siteSettings

  return (
    <>
      <Navigation navData={navData} />
      <div className="container" style={{ paddingTop: "100px" }}>
        <div dangerouslySetInnerHTML={{ __html: data.wpPage?.content }}></div>
        
      </div>
      <LocationMap />

      {/* <Location locationData={locationData} /> */}
      {/* <ContactForm /> */}
      {/* <TabbedContent charityIntroData={tabbedContent} /> */}
      <FooterGlobal />
    </>
  )
}

export const query = graphql`
  query ($slug: String!) {
    wp {
      siteSettings {
        siteSettings {
          cta {
            title
            url
          }
          facebookLink {
            url
          }
          instagramLink {
            url
          }
          linkedinLink {
            url
          }
          menu {
            navLink {
              title
              url
            }
          }
          specialField {
            subfield {
              subfieldLink {
                title
                url
              }
            }
            title
          }
          twitterLink {
            url
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 70, width: 55)
              }
            }
          }
          supportEmail
        }
      }
    }

    wpPage(slug: { eq: $slug }) {
      content
      contactpage {
        location {
          address {
            addressLine1
            addressLine2
            title
          }

          email {
            emailLine1
            emailLine2
            title
          }
          phone {
            phoneLine1
            phoneLine2
            title
          }
          text
          title
        }
        contactClubTabbed {
          firstTabHeading
          firstTabDescription
          secondTabHeading
          secondTabDescription
          thirdTabHeading
          thirdTabDescription
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(height: 534, width: 302)
              }
            }
          }
          nameClubMember
          positionInClub
        }
      }
    }
  }
`
